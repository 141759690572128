import { api } from "@/lib/api-client";
import { t } from "@lingui/macro";
import {
  DateRangePicker,
  DateValue,
  Divider,
  getKeyValue,
  Input,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { InvoiceDetailsModal } from "./InvoiceDetailsModal";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { useDebouncedValue } from "@mantine/hooks";
import { cn } from "@/utils";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";
import { getLocalTimeZone, parseDate, today } from "@internationalized/date";
import { LimeSingleSelect } from "@/Components/NextBase/LimeSelect";

type RowInvoice = {
  key: string;
  number: string;
  amount: string;
  time: string;
  canceled: boolean;
};

const perPage = 15;
export const TaxRegisterInvoices = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDateParam = searchParams.get("start");
  const endDateParam = searchParams.get("end");
  const pageParam = searchParams.get("page");
  const selectedUserId = searchParams.get("uid");
  const selectedBusinessPremiseId = searchParams.get("bid");
  const { invoiceId } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 200);

  const startDate = startDateParam
    ? parseDate(startDateParam)
    : today(getLocalTimeZone()).subtract({ months: 1 });
  const endDate = endDateParam
    ? parseDate(endDateParam)
    : today(getLocalTimeZone());

  const page = pageParam ? parseInt(pageParam) : 1;

  const { data: posAuthData, refetch: refetchPosAuth } =
    api.cookie.useGetPosToken();

  const { data, refetch, isPending, processedErrorMessage } =
    api.taxRegister.useGetOrganizationInvoices({
      query: debouncedSearchQuery,
      page,
      perPage,
      startDate: startDate.toString(),
      endDate: endDate.toString(),
      disabled: !posAuthData?.userId,
      userId: selectedUserId ? parseInt(selectedUserId) : undefined,
      businessPremiseId: selectedBusinessPremiseId ?? undefined,
    });
  const invoices = data?.invoices;

  const {
    data: users,
    isFetching: isUsersFetching,
    processedErrorMessage: usersErrorMessage,
  } = api.user.useUserList({});

  const {
    data: businessPremises,
    isFetching: isBusinessPremisesFetching,
    processedErrorMessage: businessPremisesErrorMessage,
  } = api.taxRegister.useGetOrganizationBusinessPremise({
    disabled: !posAuthData?.userId,
  });

  /**
   * This is required for pagination to work correctly,
   * since it breaks if pageCount goes undefined between
   */
  const [pageCount, setPageCount] = useState(page);
  useEffect(() => {
    if (data && data.pageCount != pageCount) {
      setPageCount(data.pageCount > 0 ? data.pageCount : 1);

      if (page > data.pageCount) {
        handlePageChange(1);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!startDateParam && !endDateParam && !invoiceId) {
      const existingSearchParams = new URLSearchParams(window.location.search);
      existingSearchParams.delete("start");
      existingSearchParams.delete("end");

      existingSearchParams.set("start", startDate.toString());
      existingSearchParams.set("end", endDate.toString());

      setSearchParams(existingSearchParams, {
        preventScrollReset: true,
        replace: true,
      });
    }
  }, []);

  const handleDateChange = (range: { start: DateValue; end: DateValue }) => {
    const newStartDate = range.start.toString();
    const newEndDate = range.end.toString();

    const existingSearchParams = new URLSearchParams(window.location.search);
    existingSearchParams.set("start", newStartDate);
    existingSearchParams.set("end", newEndDate);
    existingSearchParams.set("page", page.toString());

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const handlePageChange = (page: number) => {
    const existingSearchParams = new URLSearchParams(window.location.search);
    existingSearchParams.set("page", page.toString());

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const setSelectedUserId = (userId?: string) => {
    console.log("uid", userId);
    const existingSearchParams = new URLSearchParams(window.location.search);

    if (userId) {
      existingSearchParams.set("uid", userId);
    } else {
      existingSearchParams.delete("uid");
    }

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const setSelectedBusinessPremiseId = (businessPremiseId?: string) => {
    const existingSearchParams = new URLSearchParams(window.location.search);

    if (businessPremiseId) {
      existingSearchParams.set("bid", businessPremiseId);
    } else {
      existingSearchParams.delete("bid");
    }

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const columns = [
    {
      key: "number",
      label: t`Številka računa`,
    },
    {
      key: "amount",
      label: t`Znesek`,
    },
    {
      key: "time",
      label: t`Ura`,
    },
  ];

  const rows: RowInvoice[] = isPending
    ? Array(perPage)
        .fill(null)
        .map((_, index) => {
          return {
            key: `loading-${index}`,
            number: "XXXX-XXXXX",
            amount: "XX,XX X",
            time: "XX. XX. XXXX XX:XX",
            canceled: false,
          };
        })
    : invoices?.map((invoice) => {
        return {
          key: invoice.number,
          number: invoice.number,
          amount: invoice.totalFormatted,
          time: invoice.dateFormatted,
          canceled: invoice.canceled,
        };
      }) || [];

  return (
    <>
      <TaxRegisterAuthorizationDialog
        handleSuccessfulAuthorization={async () => {
          refetchPosAuth();
        }}
        authenticatedUserId={posAuthData?.userId}
      />

      <LimePageHeader title={t`Arhiv računov`} />

      <div className="md:px-8">
        <div className="my-2 flex flex-col gap-2">
          <div className="flex flex-col items-center justify-between gap-2 px-4 md:flex-row">
            <Input
              className="w-full"
              startContent={<LuSearch />}
              placeholder={t`Išči`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <DateRangePicker
              maxValue={today(getLocalTimeZone())}
              defaultValue={{
                start: today(getLocalTimeZone()).subtract({ days: 1 }),
                end: today(getLocalTimeZone()),
              }}
              value={{
                start: startDate,
                end: endDate,
              }}
              onChange={handleDateChange}
              className="md:max-w-64"
            />
          </div>
          <div className="flex flex-col items-center justify-between gap-2 px-4 sm:flex-row">
            <LimeSingleSelect
              label={t`Uporabnik`}
              items={
                users?.users.map((user) => {
                  return {
                    key: user.userId.toString(),
                    label: `${user.name} ${user.lastName}`,
                  };
                }) ?? []
              }
              disallowEmptySelection={false}
              selectedKeys={selectedUserId ? [selectedUserId] : []}
              onChange={(uid?: string) => setSelectedUserId(uid)}
            />

            <LimeSingleSelect
              label={t`Poslovni prostor`}
              items={
                businessPremises
                  ?.filter((bp) => bp.locationId != null)
                  .map((businessPremise) => {
                    return {
                      key: businessPremise.id.toString(),
                      label: businessPremise.businessPremiseId,
                    };
                  }) ?? []
              }
              selectedKeys={
                selectedBusinessPremiseId ? [selectedBusinessPremiseId] : []
              }
              onChange={(id?: string) => setSelectedBusinessPremiseId(id)}
              disallowEmptySelection={false}
            />
          </div>
        </div>

        <Divider />

        {processedErrorMessage && (
          <ErrorCard className="mx-4 mt-4" message={processedErrorMessage} />
        )}

        <Table
          selectionMode="single"
          onRowAction={(key) => {
            if (isPending || !invoices) return;

            const invoice = invoices.find((invoice) => invoice.number === key);
            if (!invoice) return;

            navigate(`./${invoice.id}?${searchParams.toString()}`, {
              relative: "path",
            });
          }}
          isStriped
          aria-label={t`Tabela arhiviranih računov`}
          className="p-4"
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                page={page}
                total={pageCount}
                onChange={(page) => handlePageChange(page)}
              />
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={rows}
            emptyContent={isPending ? undefined : t`Niste še izdali računov`}
          >
            {(item) => (
              <TableRow key={item.key}>
                {(columnKey) => (
                  <TableCell
                    className={cn("", {
                      "text-red-600 line-through": item.canceled,
                    })}
                  >
                    <Skeleton isLoaded={!isPending} className="rounded-xl">
                      {getKeyValue(item, columnKey)}
                    </Skeleton>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <InvoiceDetailsModal
        invoiceId={invoiceId || undefined}
        onClose={() => {
          if (invoiceId) {
            navigate(`..?${searchParams.toString()}`, { relative: "path" });
          }

          refetch();
        }}
      />
    </>
  );
};
