import TimeSpanRow from "@/Components/TimeSpanRow";
import useFeature from "@/hooks/useFeature";
import { api } from "@/lib/api-client";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Collapse,
  Combobox,
  Flex,
  NumberInput,
  Select,
  Switch,
  Text,
  Tooltip,
  useCombobox,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useEffect } from "react";
import { BsBuildingFillX } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";
import { LuCopySlash, LuTimerOff } from "react-icons/lu";
import { RiArrowDownSLine } from "react-icons/ri";
import styled from "styled-components";
import { useCalendarStore } from "../../store/useCalendarStore";

import { LimeCombobox } from "@/Components/LimeCombobox";
import classes from "./Prescheduled.module.css";

const PrescheduledAppointmentModal = ({ onClose }) => {
  const resourceCombobox = useCombobox({});
  const servicesCombobox = useCombobox({});
  const userCombobox = useCombobox({});
  useLingui();

  const { isFeatureEnabled } = useFeature();

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const {
    mutateAsync: createPrescheduled,
    isLoading: isPostPrescheduledLoading,
  } = api.appointments.usePostPrescheduled();

  const {
    services,
    users,
    resources,
    lastSelectedUserId,
    lastSelectedTime,
    lastSelectedDate,
    selectedLocationId,
    lastSelectedResourceId,
    newPreScheduledAppointmentData,
    setNewPreScheduledAppointmentData,
    calendarData,
  } = useCalendarStore((state) => state);

  const isEndInNextDay = dayjs(
    newPreScheduledAppointmentData?.endTime,
    "HH:mm",
  ).isSameOrBefore(dayjs(newPreScheduledAppointmentData?.startTime, "HH:mm"));

  const { data: validOptions } = api.appointments.usePostReturnValidOptions(
    {
      selectedServices: newPreScheduledAppointmentData?.selectedServiceId
        ? [
            {
              resources: newPreScheduledAppointmentData?.resources,
              users: newPreScheduledAppointmentData?.selectedUserId
                ? [newPreScheduledAppointmentData?.selectedUserId]
                : [],
              blockAfterMins: 0,
              blockBeforeMins: 0,
              duration:
                dayjs(newPreScheduledAppointmentData?.endTime, "HH:mm")
                  .add(isEndInNextDay ? 1 : 0, "day")
                  .diff(
                    dayjs(newPreScheduledAppointmentData?.startTime, "HH:mm"),
                    "minute",
                  ) || 60,
              serviceId: newPreScheduledAppointmentData?.selectedServiceId || 0,
            },
          ]
        : [
            {
              serviceId: services[0].serviceId,
              users: newPreScheduledAppointmentData?.selectedUserId
                ? [newPreScheduledAppointmentData?.selectedUserId]
                : [],
              resources: newPreScheduledAppointmentData?.resources || [],
              duration: 60,
            },
          ],
      selectedServiceIndex: 0,
      locationId: selectedLocationId,
      startTime: `${dayjs(lastSelectedDate).format("YYYY-MM-DD")}T${
        newPreScheduledAppointmentData?.startTime
      }`,
      enabled: newPreScheduledAppointmentData,
    },
    (!!permissions.show_appointment_warnings ||
      !!permissions.restrict_appointment_overlaps ||
      !!permissions.restrict_appointment_services ||
      !!permissions.restrict_appointment_to_shifts) &&
      !!newPreScheduledAppointmentData?.startTime,
  );

  useEffect(() => {
    setNewPreScheduledAppointmentData({
      ...newPreScheduledAppointmentData,
      startTime: dayjs(lastSelectedTime).format("HH:mm"),
      endTime: null,
      numberOfPeople: 1,
      selectedUserId: lastSelectedUserId || null,
      isRepeating: false,
      repeatInterval: 1,
      repeatType: "week",
      repeatDuration: 1,
      resources: lastSelectedResourceId ? [lastSelectedResourceId] : [],
      allowDuplicateCustomerOnAppointment:
        calendarData.defaultAllowDuplicateCustomerOnAppointment,
    });
  }, []);

  const submitData = async () => {
    if (!newPreScheduledAppointmentData) return;

    const duration = dayjs(newPreScheduledAppointmentData.endTime, "HH:mm")
      .add(isEndInNextDay ? 1 : 0, "day")
      .diff(dayjs(newPreScheduledAppointmentData.startTime, "HH:mm"), "minute");

    try {
      await createPrescheduled({
        locationId: selectedLocationId,
        users: newPreScheduledAppointmentData.selectedUserId
          ? [newPreScheduledAppointmentData.selectedUserId]
          : [],
        resources: newPreScheduledAppointmentData.resources || [],
        repeat: newPreScheduledAppointmentData.isRepeating
          ? {
              count: newPreScheduledAppointmentData.repeatDuration,
              interval: newPreScheduledAppointmentData.repeatInterval,
              type: newPreScheduledAppointmentData.repeatType,
            }
          : undefined,
        numberOfPeople: newPreScheduledAppointmentData.numberOfPeople,
        startTime: `${dayjs(lastSelectedDate).format("YYYY-MM-DD")}T${
          newPreScheduledAppointmentData.startTime
        }`,
        serviceId: newPreScheduledAppointmentData.selectedServiceId,
        duration,
        throwIfRestricted: true,
        allowDuplicateCustomerOnAppointment:
          newPreScheduledAppointmentData.allowDuplicateCustomerOnAppointment,
      });

      notifications.show({
        title: t`Termin uspešno ustvarjen`,
        color: "green",
      });

      onClose();
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response.data.canOverride) {
          const items = e.response.data.warnings;

          modals.openConfirmModal({
            title: t`Nekateri termini niso veljavni`,
            children: (
              <Flex direction={"column"}>
                {items.map((item) => (
                  <Flex
                    key={item.dateTime}
                    c={item.isRestricted ? Color.Error : undefined}
                  >
                    <Text>{item.dateTime}</Text>
                  </Flex>
                ))}
              </Flex>
            ),
            labels: {
              confirm: t`Ustvari veljavne`,
              cancel: t`Prekliči ustvarjanje`,
            },
            onConfirm: async () => {
              try {
                await createPrescheduled({
                  locationId: selectedLocationId,
                  users: [newPreScheduledAppointmentData.selectedUserId],
                  serviceId: newPreScheduledAppointmentData.selectedServiceId,
                  duration,
                  resources: newPreScheduledAppointmentData.resources || [],
                  repeat: newPreScheduledAppointmentData.isRepeating
                    ? {
                        count: newPreScheduledAppointmentData.repeatDuration,
                        interval: newPreScheduledAppointmentData.repeatInterval,
                        type: newPreScheduledAppointmentData.repeatType,
                      }
                    : undefined,
                  numberOfPeople: newPreScheduledAppointmentData.numberOfPeople,
                  startTime: `${dayjs(lastSelectedDate).format("YYYY-MM-DD")}T${
                    newPreScheduledAppointmentData.startTime
                  }`,
                  throwIfRestricted: false,
                  allowDuplicateCustomerOnAppointment:
                    newPreScheduledAppointmentData.allowDuplicateCustomerOnAppointment,
                });
                notifications.show({
                  message: t`Uspešno ste ustvarili termin`,
                  variant: "success",
                  color: "green",
                });
                useCalendarStore.getState().closeModals();
              } catch (e) {
                notifications.show({
                  message: t`Napaka pri ustvarjanju termina`,
                  color: "red",
                });
              }
            },
          });
        }
      }
      notifications.show({
        message: t`Napaka pri ustvarjanju termina`,
        color: "red",
      });
    }
  };

  useEffect(() => {
    if (
      !!permissions.show_appointment_warnings ||
      !!permissions.restrict_appointment_overlaps ||
      !!permissions.restrict_appointment_services ||
      !!permissions.restrict_appointment_to_shifts
    ) {
      if (!validOptions) return;
    }

    console.log("here");

    const appointmentStart = newPreScheduledAppointmentData
      ? dayjs(
          `${newPreScheduledAppointmentData.date}T${newPreScheduledAppointmentData.startTime}`,
        )
      : null;
    const appointmentEnd = newPreScheduledAppointmentData?.endTime
      ? dayjs(
          `${newPreScheduledAppointmentData.date}T${newPreScheduledAppointmentData.endTime}`,
        )
      : null;

    if (!appointmentStart || !appointmentEnd) return;

    const validUser = usersWithWarnings.find((u) => u.isValid);
    const validResource = resourcesWithWarnings.find((r) => r.isValid);

    console.log("valid ", validUser, validResource);

    setNewPreScheduledAppointmentData({
      ...newPreScheduledAppointmentData,
      selectedUserId: lastSelectedUserId || validUser?.id || undefined,
      resources: lastSelectedResourceId
        ? [lastSelectedResourceId]
        : validResource?.id
          ? [validResource.id]
          : [] || [],
    });
  }, [newPreScheduledAppointmentData?.selectedServiceId, validOptions]);
  const selectedResources = resources.filter((r) =>
    newPreScheduledAppointmentData?.resources?.includes(r.id),
  );

  const selectedUser = users.find(
    (u) => newPreScheduledAppointmentData?.selectedUserId === u.userId,
  );

  const selectedService = services.find(
    (s) => s.serviceId === newPreScheduledAppointmentData?.selectedServiceId,
  );

  const servicesWithWarnings = services
    .filter((s) => s.permission)
    .filter(
      (s) =>
        !validOptions ||
        validOptions.availableServices.find(
          (ser) => s.serviceId === ser.serviceId && ser.isValid,
        ),
    )
    .map((service) => {
      const availableServiceDetails = validOptions?.availableServices?.find(
        (s) => s.serviceId === service.serviceId,
      );

      return {
        ...service,
        id: service.serviceId,
        value: service.serviceId.toString(),
        label: service.name,
        relationWarning: availableServiceDetails?.relationWarning,
        shiftWarning: availableServiceDetails?.shiftWarning,
        overlapWarning: availableServiceDetails?.overlapWarning,
        isValid: availableServiceDetails
          ? availableServiceDetails.isValid
          : true,
      };
    });

  const usersWithWarnings = users
    .filter(
      (u) =>
        !validOptions ||
        validOptions.availableUsers.find(
          (usr) => u.userId === usr.userId && usr.isValid,
        ),
    )
    .map((user) => {
      const firstName = user.name?.toUpperCase;
      const lastName = user.lastName?.toUpperCase();

      const availableUserDetails = validOptions?.availableUsers?.find(
        (u) => u.userId === user.userId,
      );

      return {
        id: user.userId,
        value: user.userId.toString(),
        fullName: `${user.name} ${user.lastName}`,
        label: `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`,
        relationWarning: availableUserDetails?.relationWarning,
        shiftWarning: availableUserDetails?.shiftWarning,
        overlapWarning: availableUserDetails?.overlapWarning,
        isValid: availableUserDetails ? availableUserDetails.isValid : true,
      };
    });

  const resourcesWithWarnings = resources
    .filter(
      (rs) =>
        !validOptions ||
        validOptions.availableResources.find(
          (r) => rs.id === r.resourceId && r.isValid,
        ),
    )
    .map((r) => {
      const availableResourceDetails = validOptions?.availableResources?.find(
        (res) => res.resourceId === r.id,
      );

      return {
        id: r.id,
        value: r.id.toString(),
        label: r.label,
        relationWarning: availableResourceDetails?.relationWarning,
        shiftWarning: availableResourceDetails?.shiftWarning,
        overlapWarning: availableResourceDetails?.overlapWarning,
        isValid: availableResourceDetails
          ? availableResourceDetails.isValid
          : true,
      };
    });

  const resourceComboboxLabel =
    selectedResources.length > 0
      ? `${selectedResources[0].label} ${
          selectedResources.length > 1
            ? `+ ${selectedResources.length - 1} ${t`ostalih`}`
            : ""
        } `
      : t`Ni izbranih sredstev`;

  const userComboboxLabel = selectedUser
    ? `${selectedUser.name} ${selectedUser.lastName}`
    : t`Ni izbranih zaposlenih`;

  const serviceComboboxLabel = selectedService
    ? `${selectedService.name}`
    : t`Storitev ni izbrana`;

  const warnings = {
    serviceWarning: (
      <Tooltip label={t`Storitev se ne izvaja na tem sredstvu`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    scheduleError: (
      <Tooltip label={t`Sredstvo nima urnika`}>
        <div>
          <LuTimerOff color={Color.Error}></LuTimerOff>
        </div>
      </Tooltip>
    ),
    appointmentOverlapError: (
      <Tooltip label={t`Sredstvo je zasedeno`}>
        <div>
          <BsBuildingFillX color={Color.Error}></BsBuildingFillX>
        </div>
      </Tooltip>
    ),
    userServiceWarning: (
      <Tooltip label={t`Zaposleni ne izvaja te storitve`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    userScheduleError: (
      <Tooltip label={t`Zaposleni nima urnika`}>
        <div>
          <LuTimerOff color={Color.Error}></LuTimerOff>
        </div>
      </Tooltip>
    ),
    resourceRequired: (
      <Tooltip label={t`Storitev potrebuje sredstvo`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    userAppointmentOverlapError: (
      <Tooltip label={t`Zaposleni je zaseden`}>
        <div>
          <LuCopySlash color={Color.Error}></LuCopySlash>
        </div>
      </Tooltip>
    ),
    userRequired: (
      <Tooltip label={t`Storitev potrebuje zaposlenega`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
  };

  console.log("users ", usersWithWarnings);

  return (
    <Wrapper>
      <Flex direction={"column"} gap={"1rem"}>
        <Text fw={500}>{dayjs(lastSelectedDate).format("DD. MM. YYYY")}</Text>

        {/**
         * service combobox
         */}
        <LimeCombobox
          store={servicesCombobox}
          withArrow
          w={"100%"}
          justify={"start"}
          label={t`Storitev`}
          onOptionSubmit={(val) => {
            val = Number(val);

            const selectedService = services.find((s) => s.serviceId === val);

            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              selectedServiceId: val,
              endTime: dayjs(
                `1970-01-01T${newPreScheduledAppointmentData.startTime}`,
              )
                .add(selectedService.duration, "minutes")
                .format("HH:mm"),
            });
            servicesCombobox.closeDropdown();
          }}
        >
          <Combobox.Target>
            <button
              onClick={() => servicesCombobox.toggleDropdown()}
              className={classes.comboboxTarget}
              type="button"
            >
              <Text variant={TextVariant.Caption}>{serviceComboboxLabel}</Text>
              <Flex gap={10}>
                <Flex gap={5}>{/**warnings */}</Flex>
                <RiArrowDownSLine className={"arrow-down"} />
              </Flex>
            </button>
          </Combobox.Target>
          <Combobox.Dropdown w={"fit-content"}>
            <Combobox.Options mah={200} style={{ overflowY: "auto" }}>
              {servicesWithWarnings.map((data, index) => {
                return (
                  <Combobox.Option
                    value={data.id}
                    key={data.id}
                    active={index === 0}
                  >
                    <Flex
                      gap={"15px"}
                      align={"center"}
                      justify={"space-between"}
                    >
                      <Text
                        fw={500}
                        style={{ whiteSpace: "pre" }}
                        c={
                          newPreScheduledAppointmentData?.selectedServiceId ===
                          data.id
                            ? Color.Success
                            : Color.PrimaryText
                        }
                      >
                        {data.label}
                      </Text>
                      <Flex gap={5}>
                        {data.relationWarning
                          ? warnings.userServiceWarning
                          : ""}
                        {data.shiftWarning ? warnings.userScheduleError : ""}
                        {data.overlapWarning
                          ? warnings.userAppointmentOverlapError
                          : ""}
                      </Flex>
                    </Flex>
                  </Combobox.Option>
                );
              })}
            </Combobox.Options>
          </Combobox.Dropdown>
        </LimeCombobox>

        <Collapse in={true}>
          <Flex direction={"column"} gap={"1rem"}>
            {/**
             * user combobox
             */}
            {true && (
              <LimeCombobox
                store={userCombobox}
                withArrow
                w={"100%"}
                label={t`Zaposleni`}
                justify={"start"}
                onOptionSubmit={(value) => {
                  const val = Number(value);
                  if (newPreScheduledAppointmentData.selectedUserId !== val) {
                    console.log("set");
                    setNewPreScheduledAppointmentData({
                      ...newPreScheduledAppointmentData,
                      selectedUserId: val,
                    });
                  } else {
                    console.log("usent");
                    setNewPreScheduledAppointmentData({
                      ...newPreScheduledAppointmentData,
                      selectedUserId: null,
                    });
                  }

                  userCombobox.closeDropdown();
                }}
              >
                <Combobox.Target>
                  <button
                    onClick={() => userCombobox.toggleDropdown()}
                    className={classes.comboboxTarget}
                    type="button"
                  >
                    <Text variant={TextVariant.Caption}>
                      {userComboboxLabel}
                    </Text>
                    <Flex gap={10}>
                      <Flex gap={5}>{/**warnings */}</Flex>
                      <RiArrowDownSLine className={"arrow-down"} />
                    </Flex>
                  </button>
                </Combobox.Target>
                <Combobox.Dropdown w={"fit-content"}>
                  <Combobox.Options>
                    {usersWithWarnings.map((data, index) => {
                      return (
                        <Combobox.Option
                          value={data.id}
                          key={data.id}
                          active={index === 0}
                        >
                          <Flex
                            gap={"15px"}
                            align={"center"}
                            justify={"space-between"}
                          >
                            <Text
                              fw={500}
                              style={{ whiteSpace: "pre" }}
                              variant={TextVariant.Body}
                              c={
                                newPreScheduledAppointmentData?.selectedUserId ===
                                data.id
                                  ? Color.Success
                                  : Color.PrimaryText
                              }
                            >
                              {data.fullName}
                            </Text>
                            <Flex gap={5}>
                              {data.relationWarning
                                ? warnings.userServiceWarning
                                : ""}
                              {data.shiftWarning
                                ? warnings.userScheduleError
                                : ""}
                              {data.overlapWarning
                                ? warnings.userAppointmentOverlapError
                                : ""}
                            </Flex>
                          </Flex>
                        </Combobox.Option>
                      );
                    })}
                  </Combobox.Options>
                </Combobox.Dropdown>
              </LimeCombobox>
            )}

            {isFeatureEnabled("RESOURCES") && (
              <LimeCombobox
                store={resourceCombobox}
                withArrow
                w={"100%"}
                label={t`Sredstva`}
                justify={"start"}
                onOptionSubmit={(val) => {
                  const value = Number(val);

                  if (!newPreScheduledAppointmentData.resources.includes(val)) {
                    setNewPreScheduledAppointmentData({
                      ...newPreScheduledAppointmentData,
                      resources: [
                        ...newPreScheduledAppointmentData.resources,
                        val,
                      ],
                    });
                  } else {
                    setNewPreScheduledAppointmentData({
                      ...newPreScheduledAppointmentData,
                      resources:
                        newPreScheduledAppointmentData.resources.filter(
                          (r) => r !== val,
                        ),
                    });
                  }
                }}
              >
                <Combobox.Target>
                  <button
                    className={classes.comboboxTarget}
                    onClick={() => resourceCombobox.toggleDropdown()}
                    type="button"
                  >
                    <Text variant={TextVariant.Caption}>
                      {resourceComboboxLabel}
                    </Text>
                    <Flex gap={10}>
                      <Flex gap={5}>{/**warnings */}</Flex>
                      <RiArrowDownSLine className={"arrow-down"} />
                    </Flex>
                  </button>
                  {/* <Box
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: "4px",
                      padding: "0px 12px ",
                      lineHeight: "34px",
                      cursor: "pointer",
                    }}
                    onClick={() => resourceCombobox.toggleDropdown()}
                  >
                    {resourceComboboxLabel}
                  </Box> */}
                </Combobox.Target>
                <Combobox.Dropdown w={"fit-content"}>
                  <Combobox.Options>
                    {resourcesWithWarnings.map((data, index) => {
                      return (
                        <Combobox.Option
                          value={data.id}
                          key={data.id}
                          active={index === 0}
                        >
                          <Flex
                            gap={"15px"}
                            align={"center"}
                            justify={"space-between"}
                          >
                            <Text
                              fw={500}
                              style={{ whiteSpace: "pre" }}
                              c={
                                newPreScheduledAppointmentData?.resources?.includes(
                                  data.id,
                                )
                                  ? Color.Success
                                  : Color.PrimaryText
                              }
                            >
                              {data.label}
                            </Text>
                            <Flex gap={5}>
                              {data.relationWarning
                                ? warnings.serviceWarning
                                : ""}
                              {data.shiftWarning ? warnings.scheduleError : ""}
                              {data.overlapWarning
                                ? warnings.appointmentOverlapError
                                : ""}
                            </Flex>
                          </Flex>
                        </Combobox.Option>
                      );
                    })}
                  </Combobox.Options>
                </Combobox.Dropdown>
              </LimeCombobox>
            )}
          </Flex>
        </Collapse>

        <TimeSpanRow
          fromTimeValue={newPreScheduledAppointmentData?.startTime}
          toTimeValue={newPreScheduledAppointmentData?.endTime}
          onFromTimeChange={(value) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              startTime: value,
            });
          }}
          onToTimeChange={(value) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              endTime: value,
            });
          }}
          disableTo={!!permissions.restrict_custom_duration}
          allowAnyTime
        ></TimeSpanRow>

        <NumberInput
          label={t`Število oseb`}
          min={1}
          max={99999}
          value={newPreScheduledAppointmentData?.numberOfPeople}
          onChange={(value) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              numberOfPeople: value,
            });
          }}
        />

        <Switch
          label={t`Dovoli podvojene stranke na terminu`}
          checked={
            newPreScheduledAppointmentData?.allowDuplicateCustomerOnAppointment
          }
          onChange={(e) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              allowDuplicateCustomerOnAppointment: e.currentTarget.checked,
            });
          }}
        />

        <Switch
          label={t`Ponavljajoč termin`}
          checked={newPreScheduledAppointmentData?.isRepeating}
          onChange={(e) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              isRepeating: e.currentTarget.checked,
            });
          }}
        />
        {newPreScheduledAppointmentData?.isRepeating && (
          <Flex direction={"column"} gap={"1rem"}>
            <Flex gap={"1rem"} align={"center"} justify={"space-between"}>
              <Text style={{ whiteSpace: "pre" }}>
                <Trans>Ponovi vsak</Trans>
              </Text>
              <NumberInput
                placeholder={"1"}
                value={newPreScheduledAppointmentData?.repeatInterval}
                onChange={(value) => {
                  setNewPreScheduledAppointmentData({
                    ...newPreScheduledAppointmentData,
                    repeatInterval: value,
                  });
                }}
                variant={"filled"}
                min={1}
              />
              <Select
                data={[
                  { label: t`Dan`, value: "day" },
                  { label: t`Teden`, value: "week" },
                  { label: t`Mesec`, value: "month" },
                ]}
                value={newPreScheduledAppointmentData?.repeatType}
                onChange={(value) => {
                  setNewPreScheduledAppointmentData({
                    ...newPreScheduledAppointmentData,
                    repeatType: value,
                  });
                }}
                variant={"filled"}
                defaultValue={"week"}
                allowDeselect={false}
              />
            </Flex>
            <NumberInput
              label={t`Število ponavljanj`}
              placeholder={"1"}
              value={newPreScheduledAppointmentData?.repeatDuration}
              onChange={(value) => {
                setNewPreScheduledAppointmentData({
                  ...newPreScheduledAppointmentData,
                  repeatDuration: value,
                });
              }}
              variant={"filled"}
              min={1}
            />
          </Flex>
        )}

        <Button
          id={"submit-scheduled-appointment-button"}
          onClick={submitData}
          style={{
            display: "none",
          }}
          disabled={isPostPrescheduledLoading}
        >
          <Trans>Potrdi</Trans>
        </Button>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default PrescheduledAppointmentModal;
