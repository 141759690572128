import { LimePageHeader } from "@/Components/LimePageHeader";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { api } from "@/lib/api-client";
import { t, Trans } from "@lingui/macro";
import { useForm } from "@mantine/form";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";

export const TaxRegisterSetupCertificate = () => {
  const navigate = useNavigate();

  const { data: posAuthData, refetch: refetchPosAuth } =
    api.cookie.useGetPosToken();

  const {
    mutateAsync: uploadCertificate,
    isPending: isUploadingCertificate,
    processedErrorMessage,
  } = api.taxRegister.usePostOrganizationCertificate();

  const form = useForm<Form>({
    initialValues: {
      certificate: null,
      password: "",
    },

    validate: {
      certificate: (value) =>
        value === null ? t`Certifikat je obvezen` : null,
      password: (value) => (value === "" ? "Geslo je obvezno" : null),
    },
  });

  const handleSubmit = async (values: Form) => {
    const formData = new FormData();
    formData.append("certificate", values.certificate!);
    formData.append("password", values.password);

    await uploadCertificate(formData);

    navigate("../", {
      relative: "path",
    });
  };

  return (
    <>
      <TaxRegisterAuthorizationDialog
        handleSuccessfulAuthorization={async () => {
          refetchPosAuth();
        }}
        authenticatedUserId={posAuthData?.userId}
        requiresSetupCompleted={false}
      />

      <LimePageHeader
        title={t`Vzpostavitev certifikata`}
        subPage={{
          title: t`Certifikat`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      <div className="mt-4 flex flex-col px-4 md:px-8">
        <p className="text-sm">
          <Trans>
            Za pravilno delovanje davčne blagajne je potrebno pridobiti
            certifikat Finančne uprave RS preko portala eDavki.
          </Trans>
        </p>

        <p className="my-4 text-sm">
          <Trans>
            V kolikor niste uporabnik portala eDavki in se nanj nimate namena
            registrirati, lahko za prevzem pooblastite drugo osebo npr. vaše
            računovodstvo.
          </Trans>
        </p>

        <form
          onSubmit={form.onSubmit(handleSubmit)}
          encType="multipart/form-data"
          className="mb-2"
        >
          <LimeInput
            {...form.getInputProps("certificate")}
            value={undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files && e.target.files.length > 0) {
                form.setFieldValue("certificate", e.target.files[0]);
              }
            }}
            type="file"
          />
          <LimeInput
            className="my-2"
            label={t`Geslo certifikata`}
            {...form.getInputProps("password")}
            type="password"
          />

          <Button
            color="primary"
            type="submit"
            className="w-full"
            disabled={isUploadingCertificate}
            isLoading={isUploadingCertificate}
          >
            <Trans>Naloži</Trans>
          </Button>
        </form>

        <ErrorCard message={processedErrorMessage} />
      </div>
    </>
  );
};

type Form = {
  certificate: File | null;
  password: string;
};
