import { useEffect } from "react";
import {
  AutocompleteItem,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import { useForm } from "@mantine/form";
import { t, Trans } from "@lingui/macro";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { api } from "@/lib/api-client";
import {
  formatCurrency,
  returnCurrencySymbol,
} from "../../../../../../../shared/utils/utils";
import { cn } from "@/utils";
import { PostOrganizationInvoice } from "@/server-types";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { Check, CircleAlert, Plus, Trash2, X } from "lucide-react";
import { i18n } from "@lingui/core";
import useFeature from "@/hooks/useFeature";
import { useDebouncedState } from "@mantine/hooks";
import { LimeAutocomplete } from "@/Components/NextBase/LimeAutocomplete";

type NonCouponPaymentOption = {
  type: Exclude<
    PostOrganizationInvoice["body"]["payments"][number]["type"],
    "coupon"
  >;
  amountCents: number;
};

type CouponPaymentOption = {
  type: "coupon";
  amountCents: number;
  code: string;
};

type PaymentOption = NonCouponPaymentOption | CouponPaymentOption;

export type SplitPaymentForm = {
  options: PaymentOption[];
};

export const SplitPaymentModal = ({
  isOpen,
  handleClose,
  existingData,
  isLoading,
  currency,
  locale,
  totalPriceCents,
  totalDiscountInvoicePriceCents,
  errorMessage,
  hideCoupon,
  isChangingPaymentOnExistingInvoice,
}: {
  isOpen: boolean;
  handleClose: (data?: { options: SplitPaymentForm["options"] }) => void;
  existingData?: SplitPaymentForm["options"];
  isLoading: boolean;
  currency: string;
  locale: string;
  totalPriceCents: number;
  totalDiscountInvoicePriceCents?: number;
  errorMessage?: string | null;
  hideCoupon?: boolean;
  isChangingPaymentOnExistingInvoice?: boolean;
}) => {
  const { isFeatureEnabled } = useFeature();

  const {
    isOpen: isAddCouponToInvoiceModalOpen,
    onOpen: openAddCouponToInvoiceModal,
    onClose: closeAddCouponToInvoiceModal,
  } = useDisclosure();

  const form = useForm<SplitPaymentForm>({
    initialValues: {
      options: [],
    },
    validate: {
      options: (value) => {
        const { amountLeft } = CalculateValues({
          amountCents: totalDiscountInvoicePriceCents || totalPriceCents,
          enteredOptions: value,
        });

        if (amountLeft !== 0) {
          return t`Preostali znesek mora biti 0`;
        }

        const isAnyGiftCardInvalid = value
          .filter((value) => value.type === "coupon")
          .some(
            (value) =>
              value.type === "coupon" &&
              (!value.code ||
                value.code.length < 3 ||
                !value.amountCents ||
                value.amountCents < 0),
          );

        if (isAnyGiftCardInvalid) {
          return t`Vsi darilni boni morajo biti veljavni`;
        }

        return null;
      },
    },
  });

  useEffect(() => {
    if (isLoading) return;
    form.reset();
  }, [isLoading]);

  useEffect(() => {
    if (isLoading || !existingData) return;

    form.setValues({
      options: existingData.map((option) => ({
        ...option,
        amountCents: option.amountCents / 100,
      })),
    });
  }, [isLoading, existingData]);

  const { amountLeft, totalAmount } = CalculateValues({
    amountCents: totalDiscountInvoicePriceCents || totalPriceCents,
    enteredOptions: form.getValues().options,
  });

  const { isPrinting } = usePosPrinterStore((state) => state);

  const totalAmountFormatted = formatCurrency({
    amount: totalAmount,
    currency,
    locale,
    fractionDigits: 3,
  });

  const amountLeftFormatted = formatCurrency({
    amount: amountLeft,
    currency,
    locale,
    fractionDigits: 3,
  });

  const currencySymbol = returnCurrencySymbol({ currency });

  const handleChangeOptionAmount = (
    type: PaymentOption["type"],
    amountCents: number,
  ) => {
    const existingOptionIndex = form
      .getValues()
      .options.findIndex((option) => option.type === type);

    if (!amountCents || amountCents == 0) {
      form.removeListItem("options", existingOptionIndex);
      return;
    }

    if (existingOptionIndex >= 0) {
      form.setFieldValue(
        "options",
        form.getValues().options.map((option) => {
          if (option.type === type) {
            return {
              ...option,
              amountCents,
            };
          }
          return option;
        }),
      );
    } else {
      form.insertListItem("options", {
        type,
        amountCents,
      });
    }
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={handleClose}
        classNames={{
          closeButton: "m-2",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();

                form.validate();

                if (form.isValid()) {
                  const values = form.getValues();
                  handleClose(values);
                }
              }}
            >
              <ModalHeader>
                {isChangingPaymentOnExistingInvoice ? (
                  <Trans>Spremeni način plačila</Trans>
                ) : (
                  <Trans>Razdeli plačilo</Trans>
                )}
              </ModalHeader>
              <ModalBody>
                <div className="flex justify-around py-4 text-center">
                  <PaymentInfoBlock
                    label={t`Celoten znesek`}
                    value={totalAmountFormatted}
                  />
                  <PaymentInfoBlock
                    label={t`Preostali znesek`}
                    value={amountLeftFormatted}
                    color={
                      amountLeft === 0
                        ? "success"
                        : amountLeft !== 0
                          ? "error"
                          : undefined
                    }
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <PaymentTypeInputWrapper label={t`Gotovina`}>
                    <LimeInput
                      autoFocus
                      // {...form.getInputProps("options.cash")}
                      value={
                        form
                          .getValues()
                          .options.find((option) => option.type === "cash")
                          ?.amountCents || ""
                      }
                      onValueChange={(val) =>
                        handleChangeOptionAmount("cash", Number(val))
                      }
                      type="number"
                      step={0.01}
                      endContent={currencySymbol}
                    />
                  </PaymentTypeInputWrapper>
                  <PaymentTypeInputWrapper label={t`Kartica`}>
                    <LimeInput
                      value={
                        form
                          .getValues()
                          .options.find((option) => option.type === "card")
                          ?.amountCents || ""
                      }
                      onValueChange={(val) =>
                        handleChangeOptionAmount("card", Number(val))
                      }
                      type="number"
                      step={0.01}
                      endContent={currencySymbol}
                    />
                  </PaymentTypeInputWrapper>

                  <PaymentTypeInputWrapper label={t`Spletno plačilo`}>
                    <LimeInput
                      value={
                        form
                          .getValues()
                          .options.find((option) => option.type === "online")
                          ?.amountCents || ""
                      }
                      onValueChange={(val) =>
                        handleChangeOptionAmount("online", Number(val))
                      }
                      type="number"
                      step={0.01}
                      endContent={currencySymbol}
                    />
                  </PaymentTypeInputWrapper>

                  {isFeatureEnabled("GIFTCARD") && !hideCoupon ? (
                    <>
                      <Divider />

                      <p className="text-sm font-medium">
                        <Trans>Darilni boni</Trans>
                      </p>
                      {form
                        .getValues()
                        .options.filter((option) => option.type === "coupon")
                        .map((option) => {
                          const { amountCents, code } = option;

                          const indexInOptions = form
                            .getValues()
                            .options.findIndex(
                              (option) =>
                                option.type === "coupon" &&
                                option.code === code,
                            );

                          return (
                            <GiftCardRow
                              key={indexInOptions}
                              existingData={{
                                amountCents: amountCents!,
                                code: code!,
                              }}
                              handleDeleteCoupon={() => {
                                form.removeListItem("options", indexInOptions);
                              }}
                            />
                          );
                        })}

                      <Button
                        onPress={() => openAddCouponToInvoiceModal()}
                        variant="light"
                        className="w-fit"
                        startContent={<Plus size={16} />}
                        isDisabled={
                          form
                            .getValues()
                            .options.filter(
                              (option) =>
                                option.type === "coupon" &&
                                (option.amountCents == null ||
                                  option.code == null),
                            ).length > 0
                        }
                      >
                        <Trans>Dodaj darilni bon</Trans>
                      </Button>
                    </>
                  ) : undefined}
                </div>
              </ModalBody>
              <ModalFooter className="flex-col p-2">
                {errorMessage && (
                  <>
                    <Divider />

                    <div>
                      <ErrorCard
                        message={
                          (form.errors.options as string | undefined) ||
                          errorMessage
                        }
                      />
                    </div>
                  </>
                )}

                <Divider />
                <Button
                  className="h-12 w-full"
                  isLoading={isLoading || isPrinting}
                  color="primary"
                  type="submit"
                >
                  {isChangingPaymentOnExistingInvoice ? (
                    <Trans>Spremeni plačilo</Trans>
                  ) : (
                    <Trans>Ustvari račun</Trans>
                  )}
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>

      <AddCouponToInvoiceModal
        isOpen={isAddCouponToInvoiceModalOpen}
        handleClose={(data) => {
          if (data) {
            form.insertListItem("options", {
              type: "coupon",
              amountCents: data.amountCents,
              code: data.code,
            });
          }

          closeAddCouponToInvoiceModal();
        }}
        totalAmount={totalAmount}
        existingGiftCardCodes={form
          .getValues()
          .options.filter((option) => option.type === "coupon")
          .map((option) => option.code)}
        currencySymbol={currencySymbol}
      />
    </>
  );
};

const GiftCardRow = ({
  existingData,
  handleDeleteCoupon,
}: {
  existingData: {
    code: string;
    amountCents: number;
  };
  handleDeleteCoupon: () => void;
}) => {
  return (
    <div className="flex h-14 gap-2">
      <LimeInput
        value={existingData.code}
        isRequired
        isDisabled
        label={t`Koda`}
      />

      <LimeInput
        value={existingData.amountCents}
        type="number"
        step={0.01}
        isRequired
        isDisabled
        label={t`Vrednost`}
      />

      <Button isIconOnly onPress={handleDeleteCoupon} className="h-full">
        <Trash2 />
      </Button>
    </div>
  );
};

const AddCouponToInvoiceModal = ({
  isOpen,
  handleClose,
  totalAmount,
  existingGiftCardCodes,
  currencySymbol,
}: {
  isOpen: boolean;
  handleClose: (data?: { amountCents: number; code: string }) => void;
  totalAmount: number;
  existingGiftCardCodes?: string[];
  currencySymbol?: string;
}) => {
  const giftCardForm = useForm({
    initialValues: {
      code: "",
      amountCents: 0,
    },

    validate: {
      code: (value) => {
        if (!value || value.length < 5) {
          return t`Koda ni veljavna`;
        }

        if (existingGiftCardCodes?.includes(value)) {
          return t`Darilni bon je že dodan na račun`;
        }
      },

      amountCents: (value) => {
        if (value <= 0) {
          return t`Vrednost je obvezna`;
        }
      },
    },
  });

  useEffect(() => {
    if (!isOpen) return;

    giftCardForm.reset();
  }, [isOpen]);

  const {
    data: giftCardData,
    isFetching: isGiftCardFetching,
    processedErrorMessage: getGiftCardErrorMessage,
  } = api.giftCard.useGetGiftCardDetailsByCode(giftCardForm.values.code);

  const [giftCardSearch, setGiftCardSearch] = useDebouncedState("", 200);
  const {
    data: searchedGiftCards,
    isFetching: isSearchedGiftCardsFetching,
    processedErrorMessage: getSearchedGiftCardsErrorMessage,
  } = api.giftCard.useGetGiftCards({
    search: giftCardSearch,
    disabled: giftCardSearch.length < 2,
  });

  useEffect(() => {
    if (giftCardData?.giftCard) {
      giftCardForm.setFieldValue(
        "amountCents",
        Math.min(
          giftCardData.giftCard.remainingBalanceCents / 100 || 0,
          totalAmount,
        ),
      );
    }
  }, [giftCardData]);

  const isGiftCardExpired =
    giftCardData?.giftCard?.expiryDate &&
    new Date() > new Date(giftCardData?.giftCard?.expiryDate);

  const renderEndContent = () => {
    if (isGiftCardFetching || isSearchedGiftCardsFetching)
      return <Spinner size="sm" />;

    const isCodeValid =
      giftCardForm.getValues().code &&
      giftCardForm.getValues().code.length >= 5 &&
      giftCardData;
    if (!isCodeValid || !giftCardData) return null;

    if (giftCardData.found && giftCardData.giftCard) {
      if (isGiftCardExpired) {
        return <CircleAlert color="orange" size={20} />;
      }

      return <Check color="green" size={20} />;
    }

    if (!giftCardData.found) {
      return <X color="red" size={20} />;
    }

    return null;
  };

  const { locale } = i18n;

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={handleClose}
      classNames={{
        closeButton: "m-2",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              giftCardForm.validate();

              const remainingOnCard =
                (giftCardData?.giftCard?.remainingBalanceCents || 0) / 100;

              const isValueInRange =
                Number(giftCardForm.getValues().amountCents) <= remainingOnCard;

              if (!isValueInRange) {
                giftCardForm.setFieldError(
                  "amountCents",
                  t`Vrednost mora biti med 0 in` + " " + remainingOnCard,
                );
              }

              if (giftCardForm.isValid() && isValueInRange) {
                const values = giftCardForm.getValues();
                handleClose(values);
              }
            }}
          >
            <ModalHeader>
              <Trans>Dodaj darilni bon</Trans>
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col">
                <ErrorCard message={getGiftCardErrorMessage} />

                <LimeAutocomplete
                  items={
                    giftCardSearch.length < 2
                      ? []
                      : (searchedGiftCards?.giftCards || []).map(
                          (giftCard) => ({
                            label: giftCard.code,
                            value: giftCard.code,
                          }),
                        )
                  }
                  label={t`Koda`}
                  endContent={renderEndContent()}
                  placeholder={t`Vnesite kodo darilnega bona`}
                  onInputChange={setGiftCardSearch}
                  selectedKey={giftCardForm.getValues().code}
                  onSelectionChange={(value) => {
                    giftCardForm.setFieldValue("code", value?.toString() || "");
                  }}
                >
                  {(item) => (
                    <AutocompleteItem key={item.value}>
                      {item.label}
                    </AutocompleteItem>
                  )}
                </LimeAutocomplete>
                {giftCardData?.giftCard && isGiftCardExpired && (
                  <p className="mt-1 text-right text-xs text-warning-600">
                    <Trans>Rok darilnega bona je potekel</Trans>{" "}
                    {new Date(
                      giftCardData.giftCard.expiryDate,
                    ).toLocaleDateString(locale)}
                  </p>
                )}

                <LimeInput
                  {...giftCardForm.getInputProps("amountCents")}
                  type="number"
                  step={0.01}
                  endContent={currencySymbol}
                  isRequired
                  className="mt-4"
                  isDisabled={!giftCardData?.giftCard}
                />

                {giftCardData && giftCardData.found && giftCardData.giftCard ? (
                  <div className="mt-4 text-sm text-gray-500">
                    <p>
                      {t`Preostali znesek na bonu: ${giftCardData.giftCard.remainingBalanceFormatted}`}
                    </p>
                    <p>
                      {t`Datum veljavnosti: ${new Date(
                        giftCardData.giftCard.expiryDate,
                      ).toLocaleDateString(locale)}`}
                    </p>
                  </div>
                ) : undefined}

                <ErrorCard
                  message={giftCardForm.errors.options as string | undefined}
                />
              </div>
            </ModalBody>
            <ModalFooter className="flex-col p-2">
              <Divider />
              <Button className="h-12 w-full" color="primary" type="submit">
                <Trans>Dodaj darilni bon</Trans>
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};

const CalculateValues = ({
  amountCents,
  enteredOptions,
}: {
  amountCents: number;
  enteredOptions: SplitPaymentForm["options"];
}) => {
  const enteredTotal = enteredOptions.reduce((acc, option) => {
    return acc + Number(option.amountCents);
  }, 0);
  const totalAmount = amountCents / 100;
  const amountLeft = totalAmount - enteredTotal;

  return {
    totalAmount,
    amountLeft,
  };
};

const PaymentTypeInputWrapper = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) => {
  return (
    <div className="flex items-center gap-4">
      <p className="min-w-[33%] text-sm lg:text-base">{label}</p>
      {children}
    </div>
  );
};

const PaymentInfoBlock = ({
  label,
  value,
  color,
}: {
  label: string;
  value: string;
  color?: "success" | "error";
}) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-xs font-bold uppercase text-gray-500">{label}</p>
      <p
        className={cn("text-xl font-semibold", {
          "text-green-500": color === "success",
          "text-red-500": color === "error",
        })}
      >
        {value}
      </p>
    </div>
  );
};
