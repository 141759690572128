import { api } from "@/lib/api-client";
import {
  Button,
  Card,
  Container,
  FileInput,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { parse } from "csv-parse/browser/esm";
import { useState } from "react";
import {
  BiDownload,
  BiInfoCircle,
  BiCheckCircle,
  BiXCircle,
} from "react-icons/bi";
import type { RawData, TransformedData } from "./utils";
import { transformData } from "./utils";
import { Language } from "@/server-types";

type JsonData = {
  [key: string]: string | number;
};

export const ImportTab = ({ clientId }: { clientId: number }) => {
  const [file, setFile] = useState<File | null>(null);
  const [language, setLanguage] = useState<Language>("sl");
  const [formattedData, setFormattedData] = useState<TransformedData[]>([]);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const {
    mutateAsync: validateData,
    data: validationResult,
    isSuccess: isValidationSuccess,
    isError: isValidationError,
  } = api.admin.useValidateServiceData();
  const { mutateAsync: importData } = api.admin.useImportServices();

  const handleFileChange = (payload: File | null) => {
    setFile(payload);
    setIsValidated(false);
    setFormattedData([]);
  };

  const handleValidate = async () => {
    if (!file) {
      notifications.show({
        message: "Prosim zberi CSV datoteko za validacijo",
        color: "red",
      });
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const csvData = reader.result as string;

      parse(
        csvData,
        {
          columns: true,
          skip_empty_lines: true,
        },
        async (error: Error | undefined, data: JsonData[]) => {
          if (error) {
            notifications.show({
              message: `Error parsing file: ${error.message}`,
              color: "red",
            });
            return;
          }

          const formatted = transformData(data as RawData[]);
          setFormattedData(formatted);

          try {
            const response = await validateData({
              clientId,
              services: formatted,
              language,
            });

            if (response.isValid) {
              setIsValidated(true);
              notifications.show({
                message: "Data successfully validated!",
                color: "green",
              });
            } else {
              notifications.show({
                message: "Data validation failed.",
                color: "red",
              });
            }
          } catch (error) {
            notifications.show({
              message: "Data validation error.",
              color: "red",
            });
          }
        },
      );
    };

    reader.onerror = () => {
      notifications.show({
        message: "Error reading file",
        color: "red",
      });
    };

    reader.readAsText(file);
  };

  const handleImport = async () => {
    if (!isValidated || !file) {
      notifications.show({
        message: "Please validate the data before importing.",
        color: "red",
      });
      return;
    }

    try {
      await importData({
        clientId,
        language,
        services: formattedData,
      });

      notifications.show({
        message: "File successfully imported!",
        color: "green",
      });
    } catch (importError) {
      notifications.show({
        message: `Import error.`,
        color: "red",
      });
    }
  };

  const downloadCSVTemplate = () => {
    const csvContent =
      `name,price,baseDurationMins,blockBeforeMins,blockAfterMins,workers,color,tags,description,timeOffStart,timeOffDuration, taxPercentage\n` +
      `Service 1,10,30,5,10,worker1,red,tag1,Description 1,9,60,22\n` +
      `Service 2,20,45,0,5,worker2,blue,tag2,Description 2,10,120,22`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "service_template.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container size="sm" style={{ padding: 20 }}>
      <Card shadow="sm" padding="lg" style={{ marginBottom: 20 }}>
        <Text size="xl" style={{ marginBottom: 20 }}>
          Import Services
        </Text>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
        >
          <Button onClick={downloadCSVTemplate} style={{ marginRight: 10 }}>
            <BiDownload style={{ marginRight: 10 }} />
            {"  "} Prenesi CSV predlogo
          </Button>
          <Tooltip
            label={
              <div>
                <p>
                  <strong>name:</strong> Ime storitve
                </p>
                <p>
                  <strong>price:</strong> Cena storitve v evrih (npr. 10€)
                </p>
                <p>
                  <strong>baseDurationMins:</strong> Osnovno trajanje v minutah
                </p>
                <p>
                  <strong>workers:</strong> Seznam SAMO IMEN zaposlenih ki
                  opravljajo storitev, ločenih z vejicami
                </p>
                <p>
                  <strong>color:</strong> Barva storitve (HEX) (npr. #EEEEEEE)
                </p>
                <p>
                  <strong>tags:</strong> Seznam kategorij, ločenih z vejicami
                </p>
                <p>
                  <strong>description:</strong> Opis storitve (neobvezno)
                </p>
                <p>
                  <strong>timeOffStart:</strong> Začetek odmora v minutah
                  (neobvezno)
                </p>
                <p>
                  <strong>timeOffDuration:</strong> Trajanje odmora v minutah
                  (neobvezno)
                </p>
                <p>
                  Prepričajte se, da so vsa polja pravilno izpolnjena. Če polje
                  ni obvezno, ga lahko pustite praznega.
                </p>
              </div>
            }
            position="top"
          >
            <Button>
              <BiInfoCircle size={24} />
            </Button>
          </Tooltip>
        </div>
        <FileInput
          placeholder="Naloži CSV datoteko"
          accept=".csv"
          onChange={handleFileChange}
          style={{ marginBottom: 20 }}
        />
        <Select
          label="Izberi jezik"
          placeholder="Izberi"
          value={language}
          onChange={(value) => setLanguage(value as Language)}
          data={[
            { value: "en", label: "English" },
            { value: "sl", label: "Slovenian" },
            { value: "de", label: "German" },
            { value: "hr", label: "Croatian" },
            { value: "it", label: "Italian" },
            { value: "es", label: "Spanish" },
            { value: "bs", label: "Bosanski" },
          ]}
          style={{ marginBottom: 20 }}
        />
        <Button onClick={handleValidate} fullWidth style={{ marginBottom: 10 }}>
          Validacija CSV
        </Button>
        <Button onClick={handleImport} fullWidth disabled={!isValidated}>
          Uvozi
        </Button>
      </Card>
      {isValidationSuccess && validationResult && (
        <Card shadow="sm" padding="lg" style={{ marginTop: 20 }}>
          <Text size="lg" style={{ marginBottom: 10 }}>
            Rezultati validacije:
          </Text>
          <Text>
            <strong>Validacija uspešna:</strong>{" "}
            {validationResult.isValid ? <BiCheckCircle /> : <BiXCircle />}
          </Text>
          <Text>
            <strong>Ni podvojenih imen storitev:</strong>{" "}
            {validationResult.noDuplicateServiceNames ? (
              <BiCheckCircle />
            ) : (
              <BiXCircle />
            )}
          </Text>
          <Text>
            <strong>Vsi zaposleni najdeni:</strong>{" "}
            {validationResult.allWorkersExist ? (
              <BiCheckCircle />
            ) : (
              <BiXCircle />
            )}
          </Text>
          <Text>
            <strong>Ni podvojenih imen userjev na clientu:</strong>{" "}
            {validationResult.noDuplicateFirstNames ? (
              <BiCheckCircle />
            ) : (
              <BiXCircle />
            )}
          </Text>
        </Card>
      )}
      {isValidationError && (
        <Card shadow="sm" padding="lg" style={{ marginTop: 20, color: "red" }}>
          <Text>
            Validacija ni uspela. Prosim preveri CSV datoteko in poskusi znova.
          </Text>
        </Card>
      )}
    </Container>
  );
};

export default ImportTab;
