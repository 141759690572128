import axios from "axios";

const prefix = import.meta.env.VITE_BE_HOST || "http://localhost:8080";

const getConfig = () => {
  const token = localStorage.getItem("usr_token");

  return {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
};

let calendarDataAbortController = new AbortController();
export const apiGetCalendarData = ({ locationId, view, date }) => {
  calendarDataAbortController.abort();
  calendarDataAbortController = new AbortController();

  let config = getConfig();
  config = {
    ...config,
    signal: calendarDataAbortController.signal,
    params: {
      location: locationId,
      date: date,
      view: view,
    },
  };
  return axios.get(prefix + `/calendar/2`, config);
};

export const apiUpdateCalendarFilter = (locationId, filter) => {
  return axios.post(
    prefix + `/calendar/filter`,
    {
      locationId,
      filter,
    },
    getConfig(),
  );
};

export const apiGetAppointments = ({ locationId, view, date }) => {
  calendarDataAbortController.abort();
  calendarDataAbortController = new AbortController();

  let config = getConfig();
  config = {
    ...config,
    signal: calendarDataAbortController.signal,
    params: {
      location: locationId,
      date: date,
      view: view,
    },
  };
  return axios.get(prefix + `/calendar/appointments`, config);
};

export const apiCreateBreak = (breakData) => {
  const config = getConfig();
  // TODO new api route
  return axios.post(prefix + `/calendar/break`, breakData, config);
};

export const apiCreatePreScheduledAppointment = (data) => {
  const config = getConfig();
  return axios.post(prefix + `/calendar/prescheduled`, data, config);
};

export const apiRemoveCustomerFromPreScheduledAppointment = (
  appointmentId,
  data,
) => {
  const config = getConfig();
  return axios.delete(
    prefix + `/calendar/appointment/prescheduled/${appointmentId}`,
    {
      ...config,
      data: data,
    },
  );
};

export const apiAddCustomerToPreScheduledAppointment = (
  appointmentId,
  data,
) => {
  const config = getConfig();
  return axios.post(
    prefix + `/calendar/appointment/prescheduled/${appointmentId}`,
    data,
    config,
  );
};

export const apiUpdateCustomerOnPreScheduledAppointment = (
  appointmentId,
  userAppointmentId,
  data,
) => {
  const config = getConfig();
  return axios.patch(
    prefix +
      `/calendar/appointment/prescheduled/${appointmentId}/${userAppointmentId}`,
    data,
    config,
  );
};
