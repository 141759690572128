import { Transition } from "@mantine/core";
import { useSidebarStore } from "@/stores/useSidebarStore";
import { useMediaQuery } from "@mantine/hooks";
import { cn } from "@/utils";

type BottomStickyModalProps = {
  isOpen: boolean;

  children: React.ReactNode;
};

export const BottomStickyModal = ({
  isOpen,

  children,
}: BottomStickyModalProps) => {
  const { isOpen: isSidebarOpen } = useSidebarStore();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Transition
      mounted={isOpen}
      transition={"slide-up"}
      duration={300}
      timingFunction="cubic-bezier(.22,1.41,.34,1)"
    >
      {(styles) => (
        <div
          style={styles}
          className={cn(
            "fixed bottom-3 left-3 right-3 z-50 bg-white p-3 shadow-lg",
            "duration-300 ease-in-out md:bottom-0 md:left-auto md:right-0 md:rounded-none md:shadow-none md:transition-width",
            {
              "md:w-[calc(100%-270px)]": isSidebarOpen,
              "md:w-[calc(100%-60px)]": !isSidebarOpen,
            },
          )}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
