import { Autocomplete, AutocompleteProps } from "@nextui-org/react";
import { t } from "@lingui/macro";

type LimeAutocompleteProps<T extends object> = AutocompleteProps<T> & {
  emptyContentMessage?: string; // Allow customization of the empty content message
};

export const LimeAutocomplete = <T extends object>({
  emptyContentMessage = t`Ni rezultatov`, // Default translated message
  listboxProps,
  ...props
}: LimeAutocompleteProps<T>) => {
  return (
    <Autocomplete<T>
      {...props}
      listboxProps={{
        ...listboxProps,
        emptyContent: emptyContentMessage, // Set the default or overridden message
      }}
    />
  );
};
