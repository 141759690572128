export interface RawData {
  [key: string]: string;
}

export interface TransformedData {
  name: string;
  priceBaseCents: number;
  baseDurationMins: number;
  workers: string[];
  color: string;
  tags: string[];
  description?: string;
  timeOffStart?: number;
  timeOffDuration?: number;
  blockBeforeMins: number | undefined;
  blockAfterMins: number | undefined;
  taxPercentage?: number | undefined;
}

export const transformData = (data: RawData[]): TransformedData[] => {
  return data.map((item) => {
    return {
      name: item["name"].trim(),
      priceBaseCents: parseFloat(item["price"].replace("€", "").trim()) * 100,
      baseDurationMins: parseInt(item["baseDurationMins"], 10),
      description: item["description"]?.trim() || undefined,
      timeOffStart: item["timeOffStart"]
        ? parseInt(item["timeOffStart"], 10)
        : undefined,
      timeOffDuration: item["timeOffDuration"]
        ? parseInt(item["timeOffDuration"], 10)
        : undefined,
      blockBeforeMins: item["blockBeforeMins"]
        ? parseInt(item["blockBeforeMins"], 10)
        : undefined,
      blockAfterMins: item["blockAfterMins"]
        ? parseInt(item["blockAfterMins"], 10)
        : undefined,
      workers: item["workers"]
        .split(",")
        .map((w) => w.trim())
        .filter((w) => w),
      color: item["color"]?.trim() || "",
      tags: item["tags"]
        .split(",")
        .map((t) => t.trim())
        .filter((t) => t),
      taxPercentage: item["taxPercentage"]
        ? parseFloat(item["taxPercentage"].replace(",", "."))
        : undefined,
    };
  });
};
